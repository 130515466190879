/* login.css */

body {
  background-color: #121212;
  color: #ffffff;
  font-family: Arial, sans-serif;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  background-color: #1f1f1f;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 300px;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 1rem;
  color: #ffffff;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #333;
  background-color: #2c2c2c;
  color: #ffffff;
}

.login-form input::placeholder {
  color: #999;
}

.login-form button {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #4caf50;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #45a049;
}

.login-form .register-link {
  margin-top: 1rem;
  text-align: center;
}

.login-form .register-link a {
  color: #4caf50;
  text-decoration: none;
}

.login-form .register-link a:hover {
  text-decoration: underline;
}
