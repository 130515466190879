/* roomSelection.css */
.room-selection-container {
  background-color: #1e1e1e;
  color: #e0e0e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.room-selection-container h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.room-selection-container button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 15px 30px;
  margin: 10px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.room-selection-container button:hover {
  background-color: #555;
}

.logout-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #444;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #666;
}
