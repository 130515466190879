/* App.css */
body {
  font-family: Arial, sans-serif;
}

h2 {
  text-align: center;
}

.login-page,
.register-page,
.room-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.board {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1px;
  background-color: #333;
}

.board-row {
  display: contents;
}

.character {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #eee;
  cursor: pointer;
}

.character:hover {
  background-color: #ddd;
}
