.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #121212;
}

.register-form {
  background-color: #1f1f1f;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
}

.register-form h2 {
  margin-bottom: 1rem;
  text-align: center;
  color: #ffffff;
}

.register-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #2c2c2c;
  color: #ffffff;
}

.register-form input::placeholder {
  color: #999;
}

.register-form input:focus {
  outline: 2px solid #4caf50;
  outline-offset: 2px;
}

.register-form button {
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  border: none;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-form button:hover {
  background-color: #45a049;
}

.register-form .register-link {
  margin-top: 1rem;
  text-align: center;
}

.register-form .register-link a {
  color: #4caf50;
  text-decoration: none;
}

.register-form .register-link a:hover {
  text-decoration: underline;
}
